<template>
    <el-carousel class=carousel1 :interval="4000" height="200" >

    <el-carousel-item class="carouselimg1" height="200">    
      <picture>    
        <source media="(max-width:467px)" srcset="../assets/datasources/md/carousel-1-md.png" justify="center">      
        <img src="../assets/datasources/desktop/carousel-1-desktop.png"  justify="center" height="200"> 
      </picture>
    
    </el-carousel-item>
    <el-carousel-item class="carouselimg1" arrow="always" height="200">
      <picture>
        <source media="(max-width:467px)" srcset="../assets/datasources/md/carousel-02-md.png">
        <img src="../assets/datasources/desktop/carousel-02-desktop.png" height="200">
      </picture>
      
    </el-carousel-item >
    <el-carousel-item class="carouselimg1" height="200">
      <picture>
        <source media="(max-width:467px)" srcset="../assets/datasources/md/carousel-03-md.png">
        <img src="../assets/datasources/desktop/carousel-03-desktop.png"   height="200">
      </picture>
     
    </el-carousel-item>
    <el-carousel-item class="carouselimg1" height="200">
       <picture>
        <source media="(max-width:467px)" srcset="../assets/datasources/md/carousel-04-md.png">
        <img src="../assets/datasources/desktop/carousel-04-desktop.png"  height="200">
      </picture>
      
    </el-carousel-item>
  </el-carousel>
</template>




<style scoped>
.carouselimg1 { 
  width: 99%;
  height: auto;
  margin: auto; 
  transform: translateY(-50%);  
  object-fit: contain;
  
}

.el-carousel__item:nth-child(2n) {
  background-color: white;

}

.el-carousel__item:nth-child(2n + 1) {
 background-color: white;

}

</style>

<script>
  export default {
    name: "CarouselSources",
  }
</script>