<template>
  <div>
    <el-row :gutter="20" justify="center" class="box-content">
      <el-col :xs="15" :sm="10" :md="8" :lg="6" :xl="6">
        <picture>
            <source media="(max-width:767px)" srcset="../assets/space-hand-left.png">
            <img class="services-img" src="../assets/space-hand-left.png" />
          </picture>
      </el-col>
      <el-col :xs="24" :sm="12" :md="10" :lg="12" :xl="12" align>
        <el-row class="box-content-title">
          What is
        </el-row>
        <el-row class="box-content-text">
          Magrathea is a solution designed to quickly create and deploy datalakes/data warehouses on Google Cloud,
          including the full flow of extracting, validating and making data available for consumption and activation by decision makers,
          marketing teams and other systems.
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" justify="center" class="box-content">
      <el-col :xs="20" :sm="12" :md="10" :lg="12" :xl="12" align>
        <el-row class="box-content-title">
          Transparency and security
        </el-row>
        <el-row class="box-content-text">
          The entire Magrathea infrastructure is hosted on the brand's (or agency) Google Cloud projects, with complete transparency for audits and validations. No data transits outside the brand's proprietary environments, which have full control over access and permissions.
        </el-row>
        <el-row justify="right">
          <el-col>
            <el-link type="warning" href="/privacy-policy" :underline="true" target="_blank">
              Verify ours policy privacy
            </el-link>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="10" :md="8" :lg="6" :xl="6" align>
        <img class="privacy-img" src="../assets/privacy-data.png" />
      </el-col>
    </el-row>
    <el-divider border-style="dashed">
      <img src="../assets/divider-icon.png">
    </el-divider>
    <el-row justify="center" class="box-content">
      <el-col>
        <el-row class="box-content-title" justify="center">
          <el-col :span="18">40+ data sources</el-col>
        </el-row>
        <el-row  justify="center" >
          <el-col height="200">
            <CarouselSources />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider border-style="dashed" >
      <img src="../assets/divider-icon.png">
    </el-divider>
    <el-row justify="center" class="box-content">
      <el-row justify="center">
        <el-col :xs="24" :sm="24" :md="23" :lg="16" :xl="13"  align>
          <picture>
            <source media="(max-width:767px)" srcset="../assets/services-flow-vertical.png">
            <img class="services-img" src="../assets/services-flow.png" />
          </picture>
        </el-col>
      </el-row>
      <el-row justify="center" class="box-content-text">
        <el-col :span="18">
          helps you to centralize all your data in a way that allows you to activate it in different digital marketing platforms
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<style scoped>
  .box-content {
    background: #ffffffd1;
  }
  .box-content img {
    height: 300px;
  }

  .box-content-title {
    font-size: 2em;
    color: #ff6700;
    padding: 10px;
    font-weight: bold;
    margin: 2%;
  }

  @media only screen and (max-width: 767px) {
    .box-content-title {
      justify-content: center;
    }
  }

  .box-content-quoted {
    color: #ff6700;
    font-size: 2em;
    text-align: left;
  }
  .box-content-text {
    text-align: left;
    line-height: 30px;
    padding: 15px;
    color: #747474;
    width: 75%;
  }

  @media only screen and (max-width: 767px) {
    .box-content-text {
    text-align: center;
    width: 100%;
    }
  }

  @media only screen and (min-width:767px) and (max-width:1200px) {
    .box-content-text {
    width: 100%;
    }
  }

  .el-divider--horizontal {
    border-top: 2px #ff6700 !important;
    background: #ff6700;
  }

  .services-img {
    width: 100%;
    height: auto !important;
  }

  @media only screen and (max-width: 767px) {
    .privacy-img {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .space {
      width: 100%;
      margin:auto;
      display: block;
    }
  }

 @media only screen and (max-device-width: 400px){
.demonstration{
  background-color: black;
  height: 200px;
  max-width: 100%;
  object-fit: contain;
  margin: 0;
  text-align: center;
  opacity: 0.75;
  
  
}
}

</style>

<script>
import CarouselSources from "../components/CarouselSources.vue"
import Lock from '@element-plus/icons-vue'
// const pathIcon = "@/assets/datasources/";

export default {
  name: 'Home',
  
  components: {
    CarouselSources,
    // TopicContent
    // MenuBar,
  },
};
</script>