import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import installElementPlus from './plugins/element'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAuth } from "firebase/auth"
// import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import ElementPlus from 'element-plus'
import './plugins/element-plus/index.css'
import './plugins/element-plus/index.full.js'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAFAPAhCL82ne9o71z_s-2KvAYjl3mG2fU',
  authDomain: 'mirum-br-hitchhiker.firebaseapp.com',
  projectId: 'mirum-br-hitchhiker',
  storageBucket: 'mirum-br-hitchhiker.appspot.com',
  messagingSenderId: '454282956630',
  appId: '1:454282956630:web:7de08846657653f6e750f7'
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const functionsApp = getFunctions(firebaseApp, "http://localhost:5000");
// const auth = getAuth();
// // const functionsApp = getFunctions(firebaseApp);
// // connectFunctionsEmulator(functionsApp, "localhost", 5000);

// export { auth, functionsApp };
export { functionsApp };

// import './plugins/element.css'
// createApp(App)
//   .use(router)
//   .use(ElementPlus)
//   .mount('#app')

const app = createApp(App)

app.config.globalProperties.$userMail = undefined

// installElementPlus(app)
app.use(router)
app.use(ElementPlus)
app.mount('#app')

