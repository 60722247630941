import { createRouter, createWebHistory } from 'vue-router'
// import { getCurrentInstance } from 'vue'
// const app = getCurrentInstance()

import Home from '../views/Home.vue'
import { getAuth } from "firebase/auth"

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      isPublic: true
    },
  },
  {
    path: '/verifylogin',
    name: 'verifylogin',
    component: () => import('../views/VerifyLogin.vue'),
    meta: {
      isPublic: true
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      isPublic: true
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      isPublic: true
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      isPublic: true
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      isPublic: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isPublic){
    next()
  } else {
    const auth = getAuth()
    auth.onAuthStateChanged(function(user) {
      if (user) {
        this.$userMail = user
        next()
      } else {
        next('login')
      }
    });
  }
})

export default router
