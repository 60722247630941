<template>
  <!-- <div class="common-layout"> -->
    <el-container align="center">
      <el-header v-if="userlogged">
        <router-link to="/home">Home</router-link>
        <router-link to="/about">About</router-link>
      </el-header>
      <el-header v-else >
        <el-row justify="center" style="background: white">
          <el-col span=4>
            <img src="./assets/logo.png" class="logo" />
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <router-view/>
      </el-main>

      <el-footer>

      </el-footer>
    </el-container>
  <!-- </div> -->
</template>

<style>
  .el-header,
  .el-footer {
    /* background-color: #b3c0d1; */
    color: var(--el-text-color-primary);
    text-align: center;
    line-height: 60px;
  }

  .el-main {
    color: var(--el-text-color-primary);
    text-align: center;
  }

  body {
    /* background-color: #FFEB9A; */
    font-family: 'CodeNext-Trial-Bold', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    background: url("./assets/bg-top-right.png") right top no-repeat, url("./assets/bg-page.png");
  }
  body > .el-container {
    margin-bottom: 10px;
  }
</style>

<script>

export default {
  components: {
  },
  data() {
    return {
      userlogged: typeof this.$userMail !== "undefined"
    }
  }
  
}
</script>